<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content
          class="mobile indexLayoutContent"
          style="padding: 0px"
        >
          <div
            class="page mindex payed flex-col justify-start"
            style="height: auto"
          >
            <div class="mswiper windowheight">
              <top-p :lag="lag"></top-p>
              <div class="swiper sc">
                <div :class="`swiper-wrapper`">
                  <div :class="`swiper-slide ssm0`">
                    <div class="swiper lsc0">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div
                            class="group_0_0 flex-col mao00"
                            style="height: 100%"
                          >
                            <div class="g00 flex-col" :style="``">
                              <div class="g00in flex-col" style="z-index: 1">
                                <div class="pay">
                                  <div class="line">
                                    <h2>请核对订单信息</h2>
                                  </div>

                                  <template v-if="data != null">
                                    <div class="line" v-if="data.cid != '0'">
                                      SHOW： <b>{{ data.adata.pname }}</b>
                                    </div>
                                    <div class="line" v-if="data.cid != '0'">
                                      场次： <b>{{ data.cdata.title }}</b>
                                    </div>

                                    <div class="line">
                                      {{ data.mdata.name }}
                                    </div>
                                    <div class="line">
                                      {{ data.mdata.idnumber }}
                                    </div>

                                    <div
                                      class="line"
                                      style="
                                        margin-top: 20px;
                                        line-height: 120px;
                                      "
                                    >
                                      价格：<span class="price">{{
                                        (
                                          total * 1 -
                                          parseInt(jfdk / 20)
                                        ).toFixed(2)
                                      }}</span>
                                      <span>元</span>
                                    </div>

                                    <div
                                      class="line"
                                      v-if="jflist.length > 1"
                                      style="
                                        margin-top: 20px;
                                        margin-bottom: 60px;
                                        line-height: 60px;
                                      "
                                    >
                                      使用积分：
                                      <span>
                                        <select
                                          class="payjf"
                                          v-model="jfdk"
                                          v-if="data.dealed == '0'"
                                        >
                                          <option
                                            v-for="(item, index) in jflist"
                                            :key="index"
                                            :value="item.jf"
                                          >
                                            {{ item.jf }}
                                          </option>
                                        </select>
                                        <div class="payjfdk" v-else>
                                          {{ jfdk }}
                                        </div>
                                      </span>
                                      <span
                                        >分，抵扣<b
                                          style="
                                            font-size: 42px;
                                            line-height: 1;
                                            margin-left: 10px;
                                            margin-right: 10px;
                                          "
                                          >{{ jfdk / 20 }}</b
                                        >元</span
                                      >
                                    </div>
                                  </template>

                                  <div class="paybtn" @click="h5pay">
                                    <div class="btn">支付</div>
                                  </div>
                                </div>

                                <!-- <div class="gbar flex-col justify-between">
                                  <div class="gtitles flex-col">
                                    <span data-v-3f1e1d78="" class="text_13">
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title00.png"
                                        style="height: 64px"
                                      />
                                    </span>
                                  </div>
                                  <img
                                    style="
                                      width: 200px;
                                      height: 1px;
                                      margin: 39px 0 0 0px;
                                    "
                                    class="image_2"
                                    referrerpolicy="no-referrer"
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng77a881200aafe17d1ed232488c012d1b886eefc33dd61641a8ce8eb3885bf2f1"
                                  />
                                  <div class="gdes flex-col">
                                    <div
                                      class="textline"
                                      style="margin-top: 20px; width: 520px"
                                    >
                                      趣野带你看世界-我们提供新鲜户外资讯，精选音乐/电影/书籍&nbsp;等兴趣清单，还有各类好物/集合店指南，更多户外生活方式等你来发现！
                                    </div>

                                    <div
                                      class="btns flex-row justify-start"
                                      style="
                                        margin-top: 30px;
                                        margin-bottom: 60px;
                                      "
                                    >
                                      <div
                                        class="lbtn flex-row justify-center"
                                        style="margin-right: 4rem"
                                        @click="h5pay"
                                      >
                                        <div
                                          class="lbtnin flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >提交</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> -->
                              </div>

                              <div class="backimg"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchCase,
  h5Pay,
  fetchSign,
  updateSignup,
  checkPay,
} from "../api/index";
import TopP from "../components/TopP.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar,
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel, Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    TopP,
  },
  props: {
    msg: String,
  },
  watch: {
    $route(to, from) {
      let that = this;
      // to.params为最新的参数
      // from.params为之前的参数
      console.log(to.params);
      this.id = to.params["id"];

      setTimeout(() => {
        that.swiper.slideTo(that.id[1], 500);
      }, 1000);
    },
  },
  data() {
    return {
      lag: "",
      w750: 0,

      no: "",

      ISwx: 0,

      data: null,
      total: "",

      page: "mdetallmf",

      jflist: [],

      dikou: 0,

      jfdk: 0,

      final: false,
    };
  },

  mounted() {
    // if (
    //   typeof WeixinJSBridge === "object" &&
    //   typeof WeixinJSBridge.invoke === "function"
    // ) {
    //   this.ISwx = 1;
    //   console.log("在微信");
    //   alert("在微信");
    // } else {
    //   this.ISwx = 0;
    //   console.log("不在微信");
    //   alert("不在微信");
    // }

    var userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.indexOf("micromessenger") !== -1) {
      this.ISwx = 1;
      // alert("当前页面在微信中打开");
    } else {
      this.ISwx = 0;
      // alert("当前页面不在微信中打开");
    }

    let that = this;

    let no = this.$route.params.no;

    let lag = this.$route.params.lag;

    if (no) {
      this.no = no;
    }

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    this.$el.addEventListener("scroll", that.handleScroll, true);
    window.addEventListener("resize", that.handleResize, true);

    setTimeout(() => {
      $("meta[name=referrer]").attr("content", "unsafe-url");
    }, 500);

    this.fetchSign();

    that.myswiper();
    that.myswiper0();
  },
  beforeCreate() {},
  methods: {
    takeprice(price) {
      let pstr = "";
      if (price.split(".").length > 1) {
        // console.log("takeprice:",price.split(".")[1].length);
        if (price.split(".")[1].length < 2) {
          pstr = "0";
          return String(price) + pstr;
        } else {
          pstr = "";
          return String(price) + pstr;
        }
      } else {
        return String(price) + ".00";
      }
    },

    fetchSign() {
      const that = this;
      let params = { no: this.no };
      fetchSign(params).then((res) => {
        if(that.final){
          return false;
        }
        // console.log(res);
        that.data = res.results;
        that.total = that.data.price;

        if (that.data.jfdk > 0) {
          that.jfdk = that.data.jfdk;
        }

        console.log("that.total:", that.total);

        // if (that.data.cid != "0") {
        //   that.total = that.data.cdata.price;
        // } else {
        //   that.total = that.data.adata.price;
        // }

        let jflist = [];
        let maxjf = parseInt(that.data.alljf / 20);
        if (maxjf > parseInt(that.total)) {
          maxjf = parseInt(that.total);
        }
        for (var i = 0; i < maxjf + 1; i++) {
          jflist.push({ jf: i * 20 });
        }
        that.jflist = jflist;
        if (that.data.pay == "1") {
          window.location.href = `https://www.all-fields.com/#/${that.page}/${that.data.kind}/${that.data.mid}/`;
        }
        setTimeout(() => {
          that.fetchSign();
        }, 3000);
      });
    },
    h5pay() {
      const that = this;
      let params = { jfdk: that.jfdk, out_trade_no: that.data.out_trade_no };
      console.log("params:", params);
      updateSignup(params).then((res) => {
        console.log("updateSignup res:", res);
        console.log(res.results.message);
        if (res.results.message == "1") {
          params = { out_trade_no: that.data.out_trade_no };

          checkPay(params).then((res) => {

            console.log("checkPay:",res);

            // return false;
            if (res.total == "0") {
              that.final = true;
              const h = this.$createElement;
              that.$info({
                title: "支付结果",
                class: "confirmDiv",
                content: h("div", {}, [h("p", "支付成功")]),
                onOk() {
                  let params = { no: that.no };
                  fetchSign(params).then((res) => {
                    console.log(res);
                    that.data = res.results;

                    that.$router.push(
                      `/${that.page}/${that.data.kind}/${that.data.mid}/`
                    );
                  });
                },
              });
            } else {
              if (this.ISwx == 0) {
                params = {
                  out_trade_no: this.data.out_trade_no,
                  description: `${this.data.adata.pname}[${this.data.cdata.title}]`,
                  // total: this.total * 100,
                };
                console.log("params:", params);
                h5Pay(params).then((res) => {
                  console.log("res:", res);
                  window.location.href = `${
                    res.h5_url
                  }&redirect_url=${encodeURIComponent(
                    `https://www.all-fields.com/#/${that.page}/${that.data.kind}/${that.data.mid}/`
                  )}`;
                });
              } else {
                console.log("在微信");
                window.location.href = `https://www.all-fields.com/api/getopenid.php?out_trade_no=${
                  this.data.out_trade_no
                }&gourl=${encodeURIComponent(
                  `https://www.all-fields.com/#/payview/${this.data.out_trade_no}`
                )}`;
              }
            }
          });
        }
      });
    },

    myswiper0() {
      setTimeout(() => {
        this.lswiper0 = new Swiper(".lsc0", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          freeMode: true,
        });
      }, 10);
    },

    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".sc", {
          duration: 1000,
          direction: "vertical",
          resistanceRatio: 0,
          on: {
            transitionEnd: function () {
              that.mselectIndex = this.activeIndex;
              if (that.mselectIndex > 0) {
                that.tb = false;
              } else {
                that.tb = true;
              }
            },
            init: function (swiper) {
              this.emit("transitionEnd");
            },
          },
        });
      }, 10);
    },

    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;

        this.winHeight = winHeight;
      }
    },

    resizeViewport() {
      console.log("resizeViewport m");
      let that = this;
      let bl = window.innerHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
        }
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
      }
    },

    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = window.innerHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });
      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);

      this.resizeViewport();

      $("html").css({ fontSize: this.pageWidth / 192 });
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexlm.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
