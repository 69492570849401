<template>
  <div
    :class="`topbar flex-row tbshow`"
    :style="`margin-top:0px;`"
  >
    <div class="toplan">
      <div class="image-wrapper_1 flex-col justify-between w-logo wllogo">
        <img
          @click="goIndex"
          class="w-logo-img"
          referrerpolicy="no-referrer"
          src="../assets/limgs/logo.png"
        />
      </div>



    </div>


  </div>
</template>

<script>
import $ from "jquery";
import global from "../common";
export default {
  name: "TopP",
  components: {
  },
  props: {
    
    lag: {
      type: String,
      default: "",
    },
  },
  
  data() {
    return {
    };
  },
  mounted() {
    let that = this;
    
  },
  methods: {
    
    goIndex() {
      this.$router.push(`/indexm`);
    },
  },
};
</script>

<style scoped></style>
